import * as React from "react";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Grid,
  Card,
  Checkbox,
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import ReactFileReader from 'react-file-reader';
import {
  MainInput,
  MainInputLabel,
  SecStyledInput,
  StyledInput,
  TerInput,
  centered_flex_box,
  main_button,
  reject_button,
  sec_button,
} from "../../app/components/Styles";
import { getEvent } from "../../app/store/actions/eventActions";
import { addToCart, checkoutTransfer, requestTicket } from "../../app/store/actions/userActions";
import moment from "moment";
import LocationOn from "@mui/icons-material/LocationOn";
import DirectionsIcon from "@mui/icons-material/Directions";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { isMobile } from "react-device-detect";
import { Image, notification } from "antd";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactGA from "react-ga4";
import { AccountBalance, Upload } from "@mui/icons-material";
import { uploadFile } from "react-s3";
import { config } from "../../app/config";

export const SingleEvent = ({
  getEvent,
  events,
  event,
  user,
  addToCart,
  requestTicket,
  checkoutTransfer,
}) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  const navigate = useNavigate();
  const id = useParams().id;
  const [shop, setShop] = React.useState(false);
  const [amounts, setAmounts] = React.useState({});
  const [forMe, setforMe] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [category, setCategory] = React.useState(null);
  const [receipt, setReceipt] = React.useState(null)
  const handleAddReceipt = files => {
    let file = files[0]
    console.log(file)
    setReceipt(file)
  }

  const handleDeleteReceipt = () => {
    setReceipt(null);
  }

  React.useEffect(() => {
    getEvent({ eventId: id });
    ReactGA.send({ hitType: "pageview", page: `/event/${id}`, title: "Event" });
  }, []);

  const handleShop = () => {
    if (!user) {
      notification.info({
        message: "Please login or create an account to request a ticket",
      });
      navigate("/login");
    } else {
      setShop(!shop);
    }
  };

  const handleAddToCart = async (ev) => {
    ev.preventDefault();
    const data = new FormData(ev.currentTarget);
    const items = [];
    let total = 0
    for (let key in amounts) {
      total += amounts[key]
    }
    for (let key in amounts) {
      if (amounts.hasOwnProperty(key) && amounts[key] > 0) {
        let userInfos = [];
        for (let i = 1; i <= total; i++) {
          let idx = i;
          let userInfo = {
            userId: forMe[i] ? user._id : undefined,
            name: forMe[i] ? user.name : data.get(`ticket-${idx}-name`),
            email: forMe[i] ? user.email : data.get(`ticket-${idx}-email`),
            phoneNumber: forMe[i]
              ? user.phoneNumber
              : data.get(`ticket-${idx}-mob`),
          };
          event?.moreInfo.map((info) => userInfo[info] = data.get(`ticket-${idx}-${info}`));
          console.log(idx, userInfo)
          userInfos.push(userInfo);
        }
        items.push({
          itemId: id,
          quantity: amounts[key],
          category: event?.categories[key].category,
          userInfos,
          price: amounts[key] * parseInt(event?.categories[key].price),
        });
      }
    }
    !event.byRequest && items.length > 0 && addToCart({ items, totalPrice: total }, navigate);
  };

  const handleTransfer = async (ev) => {
    ev.preventDefault();
    const data = new FormData(ev.currentTarget);
    const items = [];
    let total = 0
    for (let key in amounts) {
      total += amounts[key]
    }
    for (let key in amounts) {
      if (amounts.hasOwnProperty(key) && amounts[key] > 0) {
        let userInfos = [];
        for (let i = 1; i <= total; i++) {
          let idx = i;
          let userInfo = {
            userId: forMe[i] ? user._id : undefined,
            name: forMe[i] ? user.name : data.get(`ticket-${idx}-name`),
            email: forMe[i] ? user.email : data.get(`ticket-${idx}-email`),
            phoneNumber: forMe[i]
              ? user.phoneNumber
              : data.get(`ticket-${idx}-mob`),
          };
          event?.moreInfo.map((info) => userInfo[info] = data.get(`ticket-${idx}-${info}`));
          console.log(idx, userInfo)
          userInfos.push(userInfo);
        }
        items.push({
          itemId: id,
          quantity: amounts[key],
          category: event?.categories[key].category,
          userInfos,
          price: amounts[key] * parseInt(event?.categories[key].price),
        });
      }
    }
    let imageData;
    if (receipt) {
      imageData = await uploadFile(receipt, { ...config, dirName: "receipts" })
    } else {
      notification.error({ message: "Please Upload Receipt" })
    }

    !event.byRequest && receipt && items.length > 0 && checkoutTransfer({ items, eventId: event._id, totalPrice: total, paymentUser: data.get('paymentUser'), receipt: imageData?.location }, navigate);
    navigate("/events")
  };

  const handleRequestTicket = async (ev) => {
    ev.preventDefault();
    const data = new FormData(ev.currentTarget);
    let userInfo = {
      userId: forMe[0] ? user._id : undefined,
      name: forMe[0] ? user.name : data.get(`ticket-0-name`),
      email: forMe[0] ? user.email : data.get(`ticket-0-email`),
      phoneNumber: forMe[0] ? user.phoneNumber : data.get(`ticket-0-mob`),
    };

    event?.moreInfo.map((info) => userInfo[info] = data.get(`ticket-0-${info}`));

    const creation = {
      userInfo,
      event: event._id,
      category,
      price: total,
    };

    requestTicket({ creation }, navigate);
  };

  const handleForMe = (e) => {
    const { name, checked } = e.target;
    setforMe({ ...forMe, [name]: checked });
  };

  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    setAmounts({ ...amounts, [name.split("-")[1]]: parseInt(value) });
    let total = 0;
    for (let key in amounts) {
      if (amounts.hasOwnProperty(key) && key !== name.split("-")[1]) {
        let idx = key;
        total += parseInt(event?.categories[idx].price) * amounts[key];
      }
    }
    total += parseInt(event?.categories[name.split("-")[1]].price) * value;
    setTotal(total);
    console.log(total);
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setCategory(value);
    let total = 0;
    for (let i = 0; i < event.categories.length; i++) {
      let singleCategory = event.categories[i];
      console.log(singleCategory);
      if (singleCategory.category === value) {
        total = singleCategory.price;
      }
    }
    setTotal(total);
  };

  const generateCategories = () => {
    var items = [];
    var categories = event?.categories;
    for (let i = 0; i < categories.length; i++) {
      let category = categories[i];
      if (category.available === "false") continue;
      items.push(
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={6}>
            <Typography fontWeight="bold">
              {category.category} Category
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TerInput
              margin="normal"
              autoFocus
              fullWidth
              select
              labelId="num-label"
              id={`cat-${i}`}
              label="Number of Tickets"
              name={`cat-${i}`}
              defaultValue={0}
              onChange={handleAmountChange}
              sx={{
                color: "var(--appBg) !important",
              }}
            >
              {[0, 1, 2, 3, 4, 5].map((num) => {
                return <MenuItem value={num}>{num}</MenuItem>;
              })}
            </TerInput>
          </Grid>
          <Grid item xs={2}>
            <Typography mx={1}>{category.price}EGP</Typography>
          </Grid>
        </Grid>
      );
    }
    return items;
  };

  const generateDetails = () => {
    var items = [];
    var idx = 0;
    for (let key in amounts) {
      if (amounts.hasOwnProperty(key)) {
        let number = amounts[key];
        for (let i = 0; i < number; i++) {
          idx++;
          items.push(
            <Grid item>
              <Typography>
                Ticket {idx} - {event?.categories[key].category}
              </Typography>
              {user && (
                <Typography>
                  <Checkbox name={idx} onClick={handleForMe} color="white" />
                  For Me
                </Typography>
              )}
              <TerInput
                sx={{ mr: 1 }}
                margin="normal"
                required
                type="text"
                id={`ticket-${idx}-name`}
                label="Ticket Holder Name"
                name={`ticket-${idx}-name`}
                value={forMe[idx] ? user.name : undefined}
                focused={forMe[idx]}
              />
              <TerInput
                sx={{ mr: 1 }}
                margin="normal"
                required
                type="email"
                id={`ticket-${idx}-email`}
                label="Ticket Holder Email"
                name={`ticket-${idx}-email`}
                value={forMe[idx] ? user.email : undefined}
                focused={forMe[idx]}
              />
              <MuiPhoneNumber
                sx={{
                  "& label": {
                    color: "var(--appBg) !important",
                  },
                  "& label.Mui-focused": {
                    color: "var(--appBg) !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: "var(--appBg) !important",
                    "& fieldset": {
                      border: "1px solid var(--secPurple)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--appBg)",
                    },

                    borderRadius: 0.5,
                  },
                  my: 1,
                  mt: 2
                }}
                margin="normal"
                required
                id={`ticket-${idx}-mob`}
                label="Ticket Holder Mobile"
                name={`ticket-${idx}-mob`}
                value={forMe[idx] ? user.phoneNumber : undefined}
                focused={forMe[idx]}
                variant='outlined'
                defaultCountry={'eg'}
                preferredCountries={['eg', 'us']}
                countryCodeEditable={false}
                disableAreaCodes
              />
              {event?.moreInfo.map((info) => {
                return (
                  <TerInput
                    sx={{ mr: 1 }}
                    margin="normal"
                    required
                    type="text"
                    id={`ticket-${idx}-${info}`}
                    label={info}
                    name={`ticket-${idx}-${info}`}
                  />
                );
              })}
              <hr />
            </Grid>
          );
        }
      }
    }

    return items;
  };

  if (events?.isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main">
      <Grid container spacing={isMobile ? 2 : 0}>
        <Grid
          item
          xs={isMobile ? 12 : 5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={"420px"}
            height={"590px"}
            src={
              event.image ||
              "https://tickit-media.s3.eu-central-1.amazonaws.com/images/Template.jpg"
            }
            alt="Event Poster"
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 7}>
          <Typography sx={{ color: "var(--terColor)" }}>
            <LocationOn />
            {event.location}
          </Typography>
          <Typography fontSize={40} fontWeight="bold">
            {event.title}
          </Typography>
          <Typography>
            {moment(event.date).format("DD/MM/yyyy @ HH:mm")}
          </Typography>
          <br />
          <Typography>{event.description}</Typography>
          <br />
          {event.locationURL && (
            <Button
              sx={{ ...main_button, mr: 2 }}
              target="_blank"
              className="a2"
              href={event.locationURL}
            >
              <DirectionsIcon />
              Get Directions
            </Button>
          )}
          {event.public && !event.byRequest &&
            (event.deadline
              ? moment().isBefore(event.deadline)
              : moment().isBefore(event.date)) && (
              <Button sx={main_button} onClick={() => setShop(!shop)}>
                <AddShoppingCartIcon />
                Add To Cart
              </Button>
            )}
          {event.public && event.byRequest &&
            (event.deadline
              ? moment().isBefore(event.deadline)
              : moment().isBefore(event.date)) && (
              <Button sx={main_button} onClick={handleShop}>
                <ScheduleSendIcon />
                Request a ticket
              </Button>
            )}
          {shop && !event.byRequest && event?.paymentMethod !== "InstaPay" && (
            <Box component="form" onSubmit={handleAddToCart} mt={1}>
              <Card sx={{ padding: 2 }}>
                <Typography fontSize={26}>Add Event To Cart</Typography>
                <hr />
                <Image src={event.seatChart} preview />
                {generateCategories()}
                <hr />
                {generateDetails()}
                <Typography>Summary</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" fontSize={20}>
                      {total} EGP
                    </Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="center" justifyContent="center">
                    <Button sx={{ ...sec_button, mx: 1 }} type="submit">
                      <AddShoppingCartIcon />
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          )}
          {shop && !event.byRequest && event?.paymentMethod === "InstaPay" && (
            <Box component="form" onSubmit={handleTransfer} mt={1}>
              <Card sx={{ padding: 2 }}>
                <Typography fontSize={26}>Buy Tickets</Typography>
                <hr />
                <Image src={event.seatChart} preview />
                {generateCategories()}
                <hr />
                {generateDetails()}
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  <Typography fontWeight="bold">Pay To:</Typography>
                  {event?.paymentAddress?.map((add) => {
                    return <Typography>{add}</Typography>
                  })}
                  <ReactFileReader multipleFiles={false} handleFiles={handleAddReceipt} fileTypes={[".jpeg", ".jpg", ".png", ".svg"]}>
                    <Button fullWidth sx={{ my: 1, ...sec_button }}><Upload /> Upload Receipt</Button>
                  </ReactFileReader>
                  {receipt && <Chip label={receipt.name} onDelete={handleDeleteReceipt} sx={{ color: "var(--secColor)", bgcolor: "var(--appBg)", mb: 1 }} />}
                  <TerInput
                    margin="normal"
                    required
                    fullWidth
                    id="paymentUser"
                    label="Payment Method Username"
                    name="paymentUser"
                    autoFocus
                  />
                </Box>
                <hr />
                <Typography>Summary</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" fontSize={20}>
                      {total} EGP
                    </Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="center" justifyContent="center">
                    <Button sx={{ ...sec_button, mx: 1 }} type="submit">
                      <AccountBalance />
                      Buy Now
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          )}
          {shop && event.byRequest && (
            <Box component="form" onSubmit={handleRequestTicket} mt={1}>
              <Card sx={{ padding: 2 }}>
                <Typography fontSize={26}>Request Event Ticket</Typography>
                <hr />
                <Image src={event.seatChart} preview />
                <TerInput
                  margin="normal"
                  autoFocus
                  fullWidth
                  select
                  labelId="num-label"
                  id={`category`}
                  label="Category"
                  name={`category`}
                  onChange={handleCategoryChange}
                  sx={{
                    color: "var(--appBg) !important",
                  }}
                >
                  {event?.categories?.map((category) => {
                    if (category.available === "true")
                      return (
                        <MenuItem value={category.category}>
                          {category.category}
                        </MenuItem>
                      );
                  })}
                </TerInput>
                <hr />
                <Grid item>
                  {user && (
                    <Typography>
                      <Checkbox name={0} onClick={handleForMe} color="white" />
                      For Me
                    </Typography>
                  )}
                  <TerInput
                    sx={{ mr: 1 }}
                    margin="normal"
                    required
                    type="text"
                    id={`ticket-0-name`}
                    label="Ticket Holder Name"
                    name={`ticket-0-name`}
                    value={forMe[0] ? user.name : undefined}
                    focused={forMe[0]}
                  />
                  <TerInput
                    sx={{ mr: 1 }}
                    margin="normal"
                    required
                    type="email"
                    id={`ticket-0-email`}
                    label="Ticket Holder Email"
                    name={`ticket-0-email`}
                    value={forMe[0] ? user.email : undefined}
                    focused={forMe[0]}
                  />
                  <MuiPhoneNumber
                    sx={{
                      "& label": {
                        color: "var(--appBg) !important",
                      },
                      "& label.Mui-focused": {
                        color: "var(--appBg) !important",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "var(--appBg) !important",
                        "& fieldset": {
                          border: "1px solid var(--secPurple)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "var(--appBg)",
                        },

                        borderRadius: 0.5,
                      },
                      my: 1,
                      mt: 2
                    }}
                    margin="normal"
                    required
                    id={`ticket-0-mob`}
                    label="Ticket Holder Mobile"
                    name={`ticket-0-mob`}
                    value={forMe[0] ? user.phoneNumber : undefined}
                    focused={forMe[0]}
                    variant='outlined'
                    defaultCountry={'eg'}
                    preferredCountries={['eg', 'us']}
                    countryCodeEditable={false}
                    disableAreaCodes
                  />
                  {event?.moreInfo.map((info) => {
                    return (
                      <TerInput
                        sx={{ mr: 1 }}
                        margin="normal"
                        required
                        type="text"
                        id={`ticket-0-${info}`}
                        label={info}
                        name={`ticket-0-${info}`}
                      />
                    );
                  })}
                  <hr />
                </Grid>
                <Typography>Summary</Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" fontSize={20}>
                      {total} EGP
                    </Typography>
                  </Grid>
                  <Grid item xs={6} alignItems="center" justifyContent="center">
                    <Button sx={{ ...sec_button, mx: 1 }} type="submit">
                      <SendIcon />
                      Request
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          )}
          <hr
            style={{
              width: "100%",
              opacity: "100%",
              "border-top": "solid var(--primaryPurple) 3px",
            }}
          />
          {event.terms && (
            <Box>
              <Typography>{event.terms}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  events: state?.events,
  event: state?.events?.results,
  user: state?.auth?.user,
});

const mapDispatchToProps = { getEvent, addToCart, requestTicket, checkoutTransfer };

export default connect(mapStateToProps, mapDispatchToProps)(SingleEvent);
